import * as pg from "pg";
import admin from "firebase-admin";
import { OlliePipe } from "@ollie-sports/pipe";
import type firebase from "firebase/compat";
import type bullmq from "bullmq";
import type * as ioredis from "ioredis";
import stripe from "stripe";
import * as sendGrid from "@sendgrid/mail";
import * as crypto from "crypto";
import { generateOllieFirestoreV2, generateOllieRtdb } from "@ollie-sports/firebase";
import { AllCollectionNames, MIRRORED_COLLECTIONS, PATHS_TO_NOT_MIRROR_BY_COLLECTION } from "@ollie-sports/models";
import axios from "axios";
import _ from "lodash";
import { isDeployedCode } from "./utils/server-helpers";

type EmailTemplateServiceFn = (p: {
  to: string;
  templateId: string;
  templateData: any;
  asm?: number;
  hideWarnings?: boolean;
}) => Promise<number>;

export interface Channels {
  pulseChannel: (msg: string) => Promise<void>;
  goodNewsChannel: (msg: string) => Promise<void>;
  importantErrorsChannel: (msg: string) => Promise<void>;
  leadsChannel: (msg: string) => Promise<void>;
}

export interface InjectedServerLibraries {
  stripe: stripe;
  sendGrid: typeof sendGrid;
  crypto: typeof crypto;
  ioredis: typeof ioredis;
  bullmq: typeof bullmq;
}

export interface ServerConfig {
  projectId: string;
  firebaseCloudServerKey: string;
  closeIoApiKey: string;
  stringEncryptionKey: string;
  httpWebappRoot: string;
  httpWebAppApiRoot: string;
  httpFlexApiRoot: string;
  flexAppUrl: string;
  twilioAuthToken: string;
  sendGridValidationApiKey: string;
  merchantOnboardingSecurityKey: string;
  isDeployedCode: boolean;
  posthook: {
    apiKey: string;
    signingKey: string;
  };
  revenueCatPrivateApiKey: string;
  revenueCatPublicApiKey: string;
  universalLinkHttpBase: string;
  nmiOllieMerchantAccountSecret: string;
}

// Need to explicitly define these to prevent some typescript issues with deeply nested local dependencies
export interface CoreHelpersGetUniversalHelpers {
  app: firebase.app.App;
  olliePipe: OlliePipe;
  ollieFirestoreV2: ReturnType<typeof generateOllieFirestoreV2>;
  appOllieRtdb: ReturnType<typeof generateOllieRtdb>;
}

export interface WrappedPgPool {
  query: pg.Pool["query"];
}

// Need to explicitly define these to prevent some typescript issues with deeply nested local dependencies
export interface CoreHelpersGetServerHelpers {
  injectedServerLibraries: InjectedServerLibraries;
  emailTemplateServiceFn: EmailTemplateServiceFn;
  appFirebaseAdminApp: admin.app.App;
  appOllieFirestoreV2: ReturnType<typeof generateOllieFirestoreV2>;
  appOllieRtdb: ReturnType<typeof generateOllieRtdb>;
  getAppPgPool: () => WrappedPgPool;
  getAnalyticsPgPool: () => WrappedPgPool;
  channels: Channels;
  serverConfig: ServerConfig;
}

// Config used to init ollie-core & ollie-admin-core
export interface CoreInit {
  serverInit?: {
    libraries: InjectedServerLibraries;
    app: {
      firebaseAdminApp: admin.app.App;
    };
    pg?: {
      appPg: WrappedPgPool;
      analyticsPgPool: WrappedPgPool;
    };
    emailTemplateServiceFn: EmailTemplateServiceFn;
    channels: Channels;
    serverConfig: ServerConfig;
  };
  olliePipe: OlliePipe;
  firebaseApp: any;
  firebaseModule: any;
  enforceImmutability?: boolean;
  httpWebAppApiRoot: string;
}

export class CoreHelpers {
  private serverInitRun = false;

  // UNIVERSAL ITEMS
  private firebaseApp: firebase.app.App = false as any;
  private olliePipe: OlliePipe;
  private ollieFirestoreV2: ReturnType<typeof generateOllieFirestoreV2> = false as any;

  // SERVER ONLY ITEMS
  private emailTemplateServiceFn: EmailTemplateServiceFn = false as any;
  private injectedServerLibraries: InjectedServerLibraries = false as any;
  private appFirebaseAdminApp: admin.app.App = false as any;
  private appOllieFirestoreV2: ReturnType<typeof generateOllieFirestoreV2> = false as any;
  private appOllieRtdb: ReturnType<typeof generateOllieRtdb> = false as any;
  private channels: Channels = false as any;
  private serverConfig: ServerConfig = false as any;
  // Postgres instances are always optional
  private appPgPool: WrappedPgPool = false as any;
  private analyticsPgPool: WrappedPgPool = false as any;

  constructor(p: CoreInit) {
    const MIRRORED_COLLECTIONS_SET = new Set(MIRRORED_COLLECTIONS);

    const appOllieFirestoreV2 = generateOllieFirestoreV2({
      app: p.firebaseApp,
      firestoreModule: p.firebaseModule.firestore,
      enforceImmutability: p.enforceImmutability,
      onDocumentsWritten: async docData => {
        if (!isDeployedCode()) {
          return;
        }
        await Promise.all(
          docData
            .filter(a => MIRRORED_COLLECTIONS_SET.has(a.collection as any))
            .filter(a => {
              if (a.type === "update") {
                if (PATHS_TO_NOT_MIRROR_BY_COLLECTION[a.collection as AllCollectionNames]) {
                  let tempObj = { ...a.docChanges };
                  PATHS_TO_NOT_MIRROR_BY_COLLECTION[a.collection as AllCollectionNames]!.forEach(path => {
                    _.unset(tempObj, path);
                  });

                  tempObj = removeEmptyObjects(tempObj);

                  return Object.keys(tempObj).length > 0;
                } else {
                  return true;
                }
              } else {
                return true;
              }
            })
            .map(async data => {
              if (data.type === "delete") {
                await new Promise(res => setTimeout(res, 10000)); //Wait on deletes to give the GCP listener time first to notify the mirror of the delete
              }

              await axios.post(`${p.httpWebAppApiRoot}/mirror-and-audit/${data.collection}`, {
                triggerType: "firestore-lift-hook",
                docId: data.docId,
                clientHint: data.type,
                __updatedAtMS: data.__updatedAtMS
              });
            })
        );
      }
    });

    const appOllieRtdb = generateOllieRtdb({ app: p.firebaseApp });

    // UNIVERSAL ITEMS
    this.firebaseApp = p.firebaseApp;
    this.olliePipe = p.olliePipe;
    this.ollieFirestoreV2 = appOllieFirestoreV2;
    this.appOllieRtdb = appOllieRtdb;

    if (p.serverInit) {
      this.serverInitRun = true;

      // SERVER ONLY ITEMS
      this.injectedServerLibraries = p.serverInit.libraries;
      this.emailTemplateServiceFn = p.serverInit.emailTemplateServiceFn;
      this.channels = p.serverInit.channels;
      this.serverConfig = p.serverInit.serverConfig;
      this.appFirebaseAdminApp = p.serverInit.app.firebaseAdminApp;
      this.appOllieFirestoreV2 = appOllieFirestoreV2;
      this.appOllieRtdb = appOllieRtdb;

      if (p.serverInit.pg?.appPg) {
        this.appPgPool = p.serverInit.pg?.appPg;
      }
      if (p.serverInit.pg?.analyticsPgPool) {
        this.analyticsPgPool = p.serverInit.pg?.analyticsPgPool;
      }
    }
  }

  public getUniversalHelpers(): CoreHelpersGetUniversalHelpers {
    return {
      app: this.firebaseApp,
      olliePipe: this.olliePipe,
      ollieFirestoreV2: this.ollieFirestoreV2,
      appOllieRtdb: this.appOllieRtdb
    };
  }

  public getServerHelpers(): CoreHelpersGetServerHelpers {
    if (!this.serverInitRun) {
      throw new Error("Server helpers not available. Have not been initialized.");
    }

    return {
      injectedServerLibraries: this.injectedServerLibraries,
      emailTemplateServiceFn: this.emailTemplateServiceFn,
      appFirebaseAdminApp: this.appFirebaseAdminApp,
      appOllieFirestoreV2: this.appOllieFirestoreV2,
      appOllieRtdb: this.appOllieRtdb,

      getAppPgPool: () => {
        if (!this.appPgPool) {
          throw new Error("Cannot access app pg pool. Has not been initialized");
        }
        return this.appPgPool;
      },
      getAnalyticsPgPool: () => {
        if (!this.analyticsPgPool) {
          throw new Error("Cannot access analytics pg pool. Has not been initialized");
        }
        return this.analyticsPgPool;
      },
      channels: this.channels,
      serverConfig: this.serverConfig
    };
  }
}

function removeEmptyObjects(obj: any) {
  return _.transform(
    obj,
    (result: any, value, key) => {
      // Check if the value is a non-empty object or not an object (to include arrays, functions, etc.)
      if (_.isObject(value) && !_.isArray(value) && !_.isFunction(value)) {
        // Recursively clean the object
        value = removeEmptyObjects(value);
        if (!_.isEmpty(value)) {
          result[key] = value; // Only assign if not empty after clean-up
        }
      } else if (!_.isEmpty(value)) {
        // Directly assign if value is not an empty object
        result[key] = value;
      }
      // Empty objects are not added to the result
    },
    _.isArray(obj) ? [] : {}
  );
}
